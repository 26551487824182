import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ExternalLink,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import css from './TopbarDesktop.module.css';
import { getIsClient, getIsShopsAndBrands, getUserType, getIsApproved } from '../../../../util/userHelpers';
import { userTypes } from '../../../../util/userTypes';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const LandingPageCustomLink = ({ isAuthenticated, currentUser, clientsLanding, rest }) => {
  return (!isAuthenticated || (isAuthenticated && getIsClient(currentUser))) ? (
    <NamedLink
      className={css.topbarLink}
      name="LandingPage"
    >
      <span className={css.topbarLinkHighlightLabel}>
        <FormattedMessage id="TopbarDesktop.clientsLandingPage" />
      </span>
    </NamedLink>
  ) : (isAuthenticated && getIsShopsAndBrands(currentUser)) && (
    <ExternalLink className={css.topbarLink} href={clientsLanding?.href} target="_self" {...rest}>
      <span className={css.topbarLinkHighlightLabel}>
        <FormattedMessage id="TopbarDesktop.brandsAndStoresLanding" />
      </span>
    </ExternalLink>
  );
};

const ProfileMenu = ({ currentPage, currentUser, onLogout, userType, currentUserHasListings }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return userType === userTypes.client ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageBrandsAndProductsListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage:brands-and-products'))}
            name="ManageBrandsAndProductsListingsPage" params={{ type: "brands-and-products" }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id={"TopbarDesktop.yourBrandAndProductsListingsLink"} />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ManageShopsAndDisplaysListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage:shops-and-displays'))}
            name="ManageShopsAndDisplaysListingsPage" params={{ type: "shops-and-displays" }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id={"TopbarDesktop.yourShopsAndDisplaysListingsLink"} />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass(`InboxPage`))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserHasApprovedProfileListing,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onUpdateProfile,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  const userType = getUserType(currentUser);

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} userType={userType} currentUserHasListings={currentUserHasListings} />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;


  const clientsLanding = { href: "/p/landing-clients" }
  const linkToClientsLanding = !isAuthenticated || (isAuthenticated && getIsClient(currentUser)) ?
    clientsLanding : null;

  const landingPageCustomLink =
    <LandingPageCustomLink
      isAuthenticated={isAuthenticated}
      currentUser={currentUser}
      clientsLanding={clientsLanding}
    />;

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        linkToExternalSite={linkToClientsLanding}
      />

      <TopbarSearchForm
        className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={config}
        currentUser={currentUser}
        isAuthenticated={isAuthenticated}
      />

      {/* <CustomLinksMenu
        currentPage={currentPage}
        customLinks={customLinks}
        intl={intl}
        hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
      /> */}
      {landingPageCustomLink}
      {/* {inboxLinkMaybe} */}
      {isAuthenticatedOrJustHydrated && profileMenuMaybe}
      {signupLinkMaybe}
      {loginLinkMaybe}

      {/* <Avatar className={css.avatar} user={currentUser} disableProfileLink /> */}

    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
